import { Polygon, Shape } from "../DrawingManager";
import * as turf from "@turf/turf";
import * as jsts from "jsts";
import { reproject } from 'reproject';
import wkx from 'wkx';
const reader = new jsts.io.WKTReader();

export const nProj: string = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';
export const bProj: string = '+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs';

export const sumOfSite = (wkt: string[]) => {
  if (wkt.length === 0) {
    return 0;
  } else {
    const reader = new jsts.io.WKTReader();
    return wkt.map((r) => reader.read(r).getArea()).reduce((a, b) => a + b, 0);
  }
};

export const toTurfPolygon = (polygon: Polygon) => {
  const path = polygon.getPath();
  for (let i = 0; i < path.length; i++) {
    // @ts-ignore
    path[i].push(path[i][0]);
  }
  return turf.polygon(path);
};

export const toWKT = (shape: Shape) => {
    
  let path = shape.getPath();
  let type = shape.getType();
  if (type === "Polygon") {
    if ((shape as Polygon).multiPolygonPath !== undefined) {
      // @ts-ignore
      path = (shape as Polygon).multiPolygonPath!;
      // @ts-ignore
      for (let i = 0; i < path.length; i++) {
        // @ts-ignore
        for (let j = 0; j < path[i].length; j++) {
          // @ts-ignore
          path[i][j].push(path[i][j][0])
        }
      }
      type = "MultiPolygon";
    } else {
      // @ts-ignore
      for (let i = 0; i < path.length; i++) {
        // @ts-ignore
        path[i].push(path[i][0]);  
      }
    }
  } 
  return wkx.Geometry.parseGeoJSON(reproject({
    type: type, 
    coordinates: path,
  }, nProj, bProj)).toWkt();
  
}

export const toGeom = (wkt: string, type: turf.GeometryTypes | "Field") => {
  let coordinates = undefined;
  switch (type) {
    case "Field":
      // @ts-ignore
      coordinates = wkx.Geometry.parse(wkt).toGeoJSON().coordinates[0];
      break;
    default:
      // @ts-ignore
      coordinates = wkx.Geometry.parse(wkt).toGeoJSON().coordinates;
    break;      
  }

  const gj = {
    type: type,
    coordinates: coordinates,
  }
  const geom = reproject(gj, bProj, nProj);
  if (type === "Polygon") {
    for (let i = 0; i < geom.coordinates.length; i++) {
      geom.coordinates[i].splice(geom.coordinates[i].length - 1, 1);
    }
  }
  return geom;
}

export const getProjectSiteCenter = (polygons: Polygon[]) => {
  if (polygons.length > 0) {
    let center: number[] = polygons.map(s => turf.center({ type:"Polygon", coordinates: s.getPath() }).geometry!.coordinates)
    .reduce((a: number[], b: number[]) => [a[0] + b[0], a[1] + b[1]]);
    const len = polygons.length;
    center = [center[0] / len, center[1] / len];
    return {
      centerCoordinates: center,
      centerWKT: wkx.Geometry.parseGeoJSON(reproject({
        type: 'Point',
        coordinates: center,
      }, nProj, bProj)).toWkt()
    };
  } else {
    return {
      centerCoordinates: [0, 0],
      centerWKT: ""
    }
  }
}

export const calibratePolygon = (geom: string, calibration: any) => {
  const transform = jsts.geom.util.AffineTransformation.translationInstance(calibration.x, calibration.y);
  const wkt = reader.read(geom);
  const moved_wkt = transform.transform(wkt);
  const writer = new jsts.io.WKTWriter();
  return writer.write(moved_wkt);
}

export const check_nearby_polygon = (geom1: string, geom2: string) => {
  const wkt1 = reader.read(geom1).buffer(0.2);
  const wkt2 = reader.read(geom2).buffer(0.2);
  return wkt1.intersects(wkt2);;
}