import MapManager from "../MapManager";
import differenceBy from "lodash/differenceBy";
import { RootState } from "../modules/Index";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toGeom } from "../utils/Geometry";

// @ts-ignore
const { naver } = window;

export default function useHospitalMarkers(mapManager: MapManager) {
  const [hospitalMarkers, setHospitalMarkers] = useState([]);
  const { searchResult } = useSelector(({ searchObject }: RootState) => ({
    searchResult: searchObject.data,
  }));

  const markerOverHandler = (marker: any) => {
    marker.setZIndex(300);
  };

  const markerOutHandler = (marker: any) => {
    marker.setZIndex(200);
  };

  const markerClickHandler = (marker: any) => {
    const position = marker.getPosition();
    mapManager.setCenter(position);
    mapManager.getMap().setZoom(19, false);
  };

  const makeSearchResultHospitals = () => {
    const map = mapManager.getMap()?.getNaverMap();
    if (map == null) return;

    if (searchResult.hospitals.length) {
      const currentAllHospitalMarkers = [];
      const tempResult = [];
      const dup = {};
      searchResult.hospitals.forEach((item: any) => {
        const found = differenceBy(
          searchResult.hospitals,
          differenceBy(searchResult.hospitals, [item], "geom")
        );
        if (found.length > 1) {
          dup[item.geom] = found;
        }
      });

      // console.log('dup', dup)

      Object.keys(dup).forEach((key: any) => {
        const value = dup[key];
        const hospitalContentString = '<div class="hospital_container">'
          .concat(
            value
              .map((item: any) => {
                return `           
            <div class="content_border_name">
              <div class="hospital_icon">
                <img src="/img/hospital.png" style={{ width: '15px', height: '15px' }}>
              </div>
              ${item.name}
            </div>          
          `;
              })
              .join("")
          )
          .concat("</div>");
        const pointCenter = toGeom(
          value[0].geom,
          "Point"
        ).coordinates;
        // console.log('hospitalContentString', hospitalContentString)
        const dupPointHospitalMarker = new naver.maps.Marker({
          map: map,
          position: pointCenter,
          zIndex: 200,
          icon: {
            content: [hospitalContentString].join(""),
          },
        });
        naver.maps.Event.addListener(
          dupPointHospitalMarker,
          "mouseover",
          () => {
            markerOverHandler(dupPointHospitalMarker);
          }
        );

        naver.maps.Event.addListener(dupPointHospitalMarker, "mouseout", () => {
          markerOutHandler(dupPointHospitalMarker);
        });

        naver.maps.Event.addListener(dupPointHospitalMarker, "click", () => {
          markerClickHandler(dupPointHospitalMarker);
        });

        currentAllHospitalMarkers.push(dupPointHospitalMarker);
        tempResult.push(...dup[key]);
      });

      const nonDuplicateHospitalMarkers = differenceBy(
        searchResult.hospitals,
        tempResult,
        "geom"
      ).map((item: any) => {
        const pointCenter = toGeom(
          item.geom,
          "Point"
        ).coordinates;
        const contentString = [
          `<div class="hospital_container">            
            <div class="content_name">
              <div class="hospital_icon">
                <img src="/img/hospital.png" style={{ width: '15px', height: '15px' }}>
              </div>
              ${item.name}
            </div>          
          </div>`,
        ].join("");
        // console.log('pointCenter', pointCenter)
        const marker = new naver.maps.Marker({
          map: map,
          position: pointCenter,
          visible: true,
          zIndex: 200,
          icon: {
            content: contentString,
          },
        });

        naver.maps.Event.addListener(marker, "mouseover", () => {
          markerOverHandler(marker);
        });

        naver.maps.Event.addListener(marker, "mouseout", () => {
          markerOutHandler(marker);
        });

        naver.maps.Event.addListener(marker, "click", () => {
          markerClickHandler(marker);
        });

        return marker;
      });

      currentAllHospitalMarkers.concat(nonDuplicateHospitalMarkers);

      if (currentAllHospitalMarkers!.length > 0)
        setHospitalMarkers(currentAllHospitalMarkers);
    }
  };

  useEffect(() => {
    hospitalMarkers.forEach((markser: any) => {
      markser.setMap(null);
    });
    setHospitalMarkers([]);
    makeSearchResultHospitals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResult.hospitals]);
}
