import React, { ReactNode } from "react";
import ReactTooltip from "react-tooltip";
import { ReactComponent as ContentCopyIcon } from "../img/content_copy.svg";

interface Props {
  copyIcon?: ReactNode;
  editCopyText?: (label?: Props["label"]) => string | undefined;
  id: string;
  label?: string;
  tooltipMsg?: string | JSX.Element;
  onCopy?: () => void;
}

export default function CopyableLabel({
  copyIcon,
  editCopyText,
  id,
  label,
  tooltipMsg,
  onCopy,
}: Props) {
  const handleClick = () => {
    const text = editCopyText?.(label) ?? label;
    if (text === undefined) return;

    navigator.clipboard.writeText(text);
    onCopy?.();
  };

  return (
    <div style={{ fontSize: 14 }} onClick={handleClick}>
      {label}{" "}
      <>
        <ReactTooltip id={id} type="info">
          {tooltipMsg}
        </ReactTooltip>
        {copyIcon ?? (
          <ContentCopyIcon
            data-tip
            data-for={id}
            style={{
              stroke: "currentcolor",
              width: 14,
              verticalAlign: "bottom",
              cursor: "pointer",
            }}
          />
        )}
      </>
    </div>
  );
}
