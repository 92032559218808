

import axios from 'axios';
const APIURL = '/api';

export const getSearchObjectAPI = (wkt: string) => {
  // console.log('getSearchObjectAPI', wkt)
  return axios.post(`${APIURL}/GetSearchObject`, {
    wkt,    
  });
}
