
import styled from '@emotion/styled'

export const TitleText = styled.span`
  text-align: left;  
  font-family: Roboto;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;  
  letter-spacing: -0.05em;
  color: #00897B;  
`

export const ContentText = styled.span`
  text-align: left;  
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;  
  letter-spacing: -0.05em;
  color: #040404;  
`
export const ButtonStyle = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontSize: '13px',
  fontWeight: 700,
  border: '1px solid #cccccc',
  color: '#00897B',
  height: '32px'
}