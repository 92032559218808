import React from 'react';
import Modal from '@material-ui/core/Modal';
import { RootState } from '../modules/Index';
import ProjectList from './ProjectList';

import { useSelector, useDispatch  } from 'react-redux';
import { setShowProjectList } from '../modules/ProjectList';


/** Warning: Failed prop type: Invalid prop children 문제 해결을 위해 처리된 Wrapper => Material UI 에서만 발생. **/
export const ProjectListModal = () => {
  const { showList } = useSelector(
    ({ projectList }: RootState) => ({  
      showList: projectList.showList,    
    }),
  );

  const dispatch = useDispatch();
  return(
    <>
      <Modal
        open={showList}
        onClose={() => dispatch(setShowProjectList({
          showList: false
        }))}
      >
        <Wrapper>
          <ProjectList 
            onClose={() => dispatch(setShowProjectList({
              showList: false
            }))}
          />
        </Wrapper>        
      </Modal>  
    </>
  );
}
const Wrapper = React.forwardRef((props: any, ref: any) => (
  <span {...props} ref={ref}>
    {props.children}
  </span>
));