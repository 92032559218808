import axios from 'axios';
import { Project } from '../model/Project';

const APIURL =  '/api';
interface ProjectQuery {
  query: any,
  from?: number,
  size?: number,
  sort?: any,
}
export const createProjectAPI = (data: any) => {
  return axios.post(`${APIURL}/create/project`, data);
}

export const deleteProjectAPI = (project_id: number) => {
  const ids = [];
  ids.push(project_id);
  return axios.post(`${APIURL}/delete/projects`,{
    project_id: ids,
  });
}

export const deleteProjectsAPI = (project_ids: number[]) => {
  return axios.post(`${APIURL}/delete/projects`,{
    project_ids: project_ids,
  });
}

export const discardProjectsAPI = (project_ids: number[]) => {
  return axios.post(`${APIURL}/discard/projects`,{
    project_ids: project_ids,
  });
}

export const getProjectDefaultAPI = (project_site?: string[], set_use_distirct?: string) => {
  // console.log('API ',project_site,  set_use_distirct)
  return axios.post(`${APIURL}/ProjectDefault`, {
    project_site: project_site,
    // project_use_district: this.state.currentProject.project_use_district,
    "용도지역": set_use_distirct,
  });
}

export const getProjectListAllinOneAPI = (query: ProjectQuery) => {     // result 결과까지 한번에 
  return axios.post(`${APIURL}/GetProjectList`, {
    ...query,
    table: "platform-buildit-project",
    // query: "discarded:false AND deleted:false AND project_type: AI AND ( NOT hidden:true ) AND (NOT status:CONFIG) " + ( query.query.length > 0 && `AND ${query.query}` || "" ),
    //  query: "", size: pageConfig.size, from: (pageConfig.curPageNum - 1) * pageConfig.size, sort: [{ created_at: 'desc' }] 
  });
}


export const getProjectResultAPI = (project: Project) => {
  return axios.post(`${APIURL}/GetProjectResult`, {
    project: project,
  });
}

export const getProjectFailReasonAPI = (project: Project) => {
  return axios.post(`${APIURL}/GetProjectFailReason`, {
    project: project,
  });
}

export const modRentPossibilityAPI = (project_id: number, value: number) => {
  return axios.post(`${APIURL}/ModRentPossibility`, {
    project_id: project_id,
    value: value
  });
}