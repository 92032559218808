import { createAction, handleActions } from 'redux-actions';

export const OK_CONFIRM = 'OK_CONFIRM';
export const CANCEL_CONFIRM = 'CANCEL_CONFIRM';
const SET_CONFIRM_TYPE = 'SET_CONFIRM_TYPE';
const INITIALIZE_CONFIRM = 'INITIALIZE_CONFIRM';

export const okConfirm = createAction(OK_CONFIRM);
export const cancelConfirm = createAction(CANCEL_CONFIRM);
export const setConfirmType = createAction(SET_CONFIRM_TYPE);
export const initializeConfirm = createAction(INITIALIZE_CONFIRM);

interface ConfirmState {
  title?: string;
  children?: string;
  open: boolean;  
}

const initialState: ConfirmState = {
  title: '',
  children: '',
  open: false,
};
  
const confirm = handleActions<ConfirmState, any>(
  {
    [OK_CONFIRM]: (state, { payload }) => ({
      ...state,
      open: false,
    }),
    [CANCEL_CONFIRM]: (state, { payload }) => ({
      ...state,
      open: false,
    }),
    [SET_CONFIRM_TYPE]: (state, { payload }) => ({            
      title: payload.title,
      children: payload.children,      
      open: true
    }),
    [INITIALIZE_CONFIRM]: () => initialState,
  },
  initialState,
);

export default confirm;