import { createAction, handleActions } from 'redux-actions';
// import { toast } from 'react-toastify';
// import { RootState } from './Index';

const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE';
const SHOW_INFO_MESSAGE = 'SHOW_INFORM_MESSAGE';
const SHOW_SUCCESS_MESSAGE = 'SHOW_SUCCESS_MESSAGE';
const SHOW_WARNING_MESSAGE = 'SHOW_WARNING_MESSAGE';

const INITIALIZE_INFORM_MESSAGES = 'INITIALIZE_INFORM_MESSAGES';

const CLOSE_ERROR_MESSAGE = 'CLOSE_ERROR_MESSAGE';
const CLOSE_INFO_MESSAGE = 'CLOSE_INFO_MESSAGE';
const CLOSE_SUCCESS_MESSAGE = 'CLOSE_SUCCESS_MESSAGE';
const CLOSE_WARNING_MESSAGE = 'CLOSE_WARNING_MESSAGE';

export const showErrorMessage = createAction(SHOW_ERROR_MESSAGE);
export const showInfoMessage = createAction(SHOW_INFO_MESSAGE);
export const showSuccessMessage = createAction(SHOW_SUCCESS_MESSAGE);
export const showWarningMessage = createAction(SHOW_WARNING_MESSAGE);

export const closeErrorMessage = createAction(CLOSE_ERROR_MESSAGE);
export const closeInfoMessage = createAction(CLOSE_INFO_MESSAGE);
export const closeSuccessMessage = createAction(CLOSE_SUCCESS_MESSAGE);
export const closeWarningMessage = createAction(CLOSE_WARNING_MESSAGE);

export const initializeInformMessage = createAction(INITIALIZE_INFORM_MESSAGES);

interface DefaultInform {
  msg?: string;
  type?: string;
  autoClose?: number;
  show?: boolean;
  width?: number;
  errorMessage?: string;
}

export interface InformState {
  success: DefaultInform | undefined;
  error: DefaultInform | undefined;
  info: DefaultInform | undefined;
  warning: DefaultInform | undefined;
}

const initialState: InformState = {  
  success: undefined,
  error: undefined,
  info: undefined,  
  warning: undefined,
};
  
const informMessage = handleActions<InformState, any>(
  {  
    [SHOW_ERROR_MESSAGE]: (state, { payload }) => ({
      ...state,
      error: { 
        msg: payload.msg,
        type: 'error',
        autoClose: payload.autoClose,
        show: true,
        width: payload.width || 370,
        errorMessage: payload.errorMessage,
      },
    }),
    [SHOW_INFO_MESSAGE]: (state, { payload }) => ({
      ...state,
      info: { 
        msg: payload.msg,
        type: 'info',
        autoClose: payload.autoClose,
        show: true,
        width: payload.width || 370,
      },
    }),
    [SHOW_SUCCESS_MESSAGE]: (state, { payload }) => ({
      ...state,
      success: { 
        msg: payload.msg,
        type: 'success',
        autoClose: payload.autoClose,
        show: true,
        width: payload.width || 370,
      },
    }),
    [SHOW_WARNING_MESSAGE]: (state, { payload }) => ({
      ...state,
      warning: { 
        msg: payload.msg,
        type: 'warning',
        autoClose: payload.autoClose,
        show: true,
        width: payload.width || 370,
      },
    }),

    [CLOSE_ERROR_MESSAGE]: (state, { payload }) => ({
      ...state,
      error: initialState.error,
    }),
    [CLOSE_INFO_MESSAGE]: (state, { payload }) => ({
      ...state,
      info: initialState.info,
    }),
    [CLOSE_SUCCESS_MESSAGE]: (state, { payload }) => ({
      ...state,
      success: initialState.success,
    }),
    [CLOSE_WARNING_MESSAGE]: (state, { payload }) => ({
      ...state,
      warning: initialState.warning,
    }),
    [INITIALIZE_INFORM_MESSAGES]: () => initialState,
  },
  initialState,
);

export default informMessage;