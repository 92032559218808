import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../modules/Index";
import { modifyProject } from "../../modules/Project";
import {
  FlexContainer,
  HorizontalEmptyDivide,
} from "../../styledComponents/layout";
import { RowsKeyNumInputValue } from "../common/input/RowsKeyInputValue";
import ShapeTypeButton from "./ShapeTypeButton";
export const ExtraInfoSection = () => {
  const { projectData } = useSelector(({ project }: RootState) => ({
    projectData: project.data,
  }));

  const dispatch = useDispatch();

  const onChangeBuildingStories = (event: any) => {
    const targetValue = Number(event.target.value);
    dispatch(
      modifyProject({
        building_stories_max: targetValue,
        building_stories_min: targetValue,
        building_stories_avg: targetValue,
      })
    );
  };

  return (
    <FlexContainer
      direction="column"
      height="auto"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
    >
      <HorizontalEmptyDivide />
      <ShapeTypeButton type="PROJECT_SITE" labelTitle="대지영역" />
      <HorizontalEmptyDivide />
      <ShapeTypeButton
        type="VACANCY_INSIDE"
        labelTitle="배치제한영역 추가"
        runTitle="설정하기"
      />
      <HorizontalEmptyDivide />
      <FlexContainer
        width="90%"
        height="auto"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <RowsKeyNumInputValue
          keyName="최고층수 입력"
          // keyName="평당 공사비(만원)"

          value={projectData.building_stories_max}
          inputName="최고 층수 입력"
          width="48%"
          onChange={onChangeBuildingStories}
          disableUnderline
          help="설계 검토에 반영할 건물 최고 층수를 입력할 수 있습니다"
          boxWidth="100%"
          inputProps={{ min: 1, max: 5 }}
        />
      </FlexContainer>
    </FlexContainer>
  );
};

export default ExtraInfoSection;
