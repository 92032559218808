import styled from '@emotion/styled'

export const DottedBoxContainer = styled.div`  
  display: flex;
  width: 96%;
  padding: 0;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px dotted #808080;  
`;

export const BoxValue = styled.div`  
  width: 90%;
  text-align: right;
  padding: 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.05em;
  color: #808080;  
  
`;

export const BoxUnit = styled.div`  
  width: 10%;
  padding-left: 2px;  
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.05em;
  color: #808080;
`;