import axios from 'axios';

const APIURL =  '/api';

export const generateRoadLineAPI = (project_site?: string[]) => {
  return axios.post(`${APIURL}/roadline/generator`, {
    project_site: project_site,
  });
}

export const getRoadSiteAPI = (project_site: string[], road_value: number[][]) => {
  return axios.post(`${APIURL}/roadsite`, {
    project_site: project_site,
    road_value: road_value,
  });
}