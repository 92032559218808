import { createAction, handleActions } from 'redux-actions';
import { call, put, debounce } from 'redux-saga/effects';

import { getSearchObjectAPI } from '../api/SearchObjectApi';
import wkx from 'wkx';
import { showWarningMessage } from '../modules/InformMessage';
import { bProj, nProj } from '../utils/Geometry';
const { reproject } = require('reproject');

const SEARCH_OBJECTS = 'SEARCH_OBJECTS';
const SEARCH_OBJECTS_SUCCESS = 'SEARCH_OBJECTS_SUCCESS';
const SEARCH_OBJECTS_FAILURE = 'SEARCH_OBJECTS_FAILURE';

export const searchObjects = createAction(SEARCH_OBJECTS);
export const searchObjectsSuccess = createAction(SEARCH_OBJECTS_SUCCESS);
export const searchObjectsFailure = createAction(SEARCH_OBJECTS_FAILURE);

const INITIALIZE_SEARCH_OBJECT = 'INITIALIZE_SEARCH_OBJECT';

export const initializeSearchObject = createAction(INITIALIZE_SEARCH_OBJECT);

function* searchObjectsSaga (action: any) {
  
  const zoomLevel = Number(action.payload.zoom);
  // console.log('zoom level : ', zoomLevel)
  if(zoomLevel < 16) {
    yield put(showWarningMessage({
      msg: "현재 줌 레벨에서는 주변 병원 및 학교를 검색 할 수 없습니다.",
      width: 500,
      autoClose: 3000,   
    }));
    return;
  }
  const bounds = action.payload.bounds;
  const coords = [[
    [bounds._min.x, bounds._min.y],
    [bounds._min.x, bounds._max.y],
    [bounds._max.x, bounds._max.y],
    [bounds._max.x, bounds._min.y],
    [bounds._min.x, bounds._min.y]
  ]];

  const mapWKT = wkx.Geometry.parseGeoJSON(reproject({
    type: "Polygon", 
    coordinates: coords,
  }, nProj, bProj)).toWkt();

  try{
    const { data: responseData } = yield call(getSearchObjectAPI, mapWKT);
    if(responseData.success) {
      yield put(searchObjectsSuccess({
        schools: responseData.schools,
        hospitals: responseData.hospitals
      }))
    } else {
      yield put(initializeSearchObject());
    }
  } catch (e) {
    console.log('error', e)
  }
  
}

export function* watchSearchObject() {
  yield debounce(1000, 'SEARCH_OBJECTS', searchObjectsSaga);
}

type state = {  
  data?: any;    
  error?: string;
  loading: boolean;
};

const initialState: state = {
  data: {
    hospitals: [],
    schools: []
  },
  loading: false,
};
  
const searchObject = handleActions<state, any> ( // success
  {    
    [SEARCH_OBJECTS]: (state, { payload }) => ({
      ...state, 
      loading: true,      
    }),
    [SEARCH_OBJECTS_SUCCESS]: (state, { payload }) => ({
      ...state, 
      data: {
        hospitals: payload.hospitals,
        schools: payload.schools
      },
      loading: false,      
    }),
    [INITIALIZE_SEARCH_OBJECT]: () => initialState,
  },
  initialState,
)

export default searchObject;
