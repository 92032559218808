export const building_type = [
  {
    value: "다세대주택",
    label: "다세대주택",
  }, 
  // {
  //   value: "상가주택",
  //   label: "상가주택",
  // }, 
  {
    value: "오피스텔",
    label: "오피스텔",
  }
]

export const list_view_count = [
  {
    value: 10,
    label: "10개 보기"
  },
  {
    value: 20,
    label: "20개 보기"
  },
  {
    value: 30,
    label: "30개 보기"
  },
  {
    value: 40,
    label: "40개 보기"
  },
  {
    value: 50,
    label: "50개 보기"
  }
]

export const project_use_district = [
  {
    value: '제1종전용주거지역',
    label: '제1종전용주거지역'
  }, {
    value: '제2종전용주거지역',
    label: '제2종전용주거지역'
  }, {
    value: '제1종일반주거지역',
    label: '제1종일반주거지역'
  }, {
    value: '제2종일반주거지역',
    label: '제2종일반주거지역'
  }, {
    value: '제3종일반주거지역',
    label: '제3종일반주거지역'
  }, {
    value: '준주거지역',
    label: '준주거지역'
  }, {
    value: '중심상업지역',
    label: '중심상업지역'
  }, {
    value: '일반상업지역',
    label: '일반상업지역'
  }, {
    value: '근린상업지역',
    label: '근린상업지역'
  }, {
    value: '유통상업지역',
    label: '유통상업지역'
  }, {
    value: '전용공업지역',
    label: '전용공업지역'
  }, {
    value: '일반공업지역',
    label: '일반공업지역'
  }, {
    value: '준공업지역',
    label: '준공업지역'
  }, {
    value: '보전녹지지역',
    label: '보전녹지지역'
  }, {
    value: '생산녹지지역',
    label: '생산녹지지역'
  }, {
    value: '자연녹지지역',
    label: '자연녹지지역'
  }, {
    value: '보전관리지역',
    label: '보전관리지역'
  }, {
    value: '생산관리지역',
    label: '생산관리지역'
  }, {
    value: '계획관리지역',
    label: '계획관리지역'
  }, {
    value: '농림지역',
    label: '농림지역'
  }, {
    value: '자연환경보전지역',
    label: '자연환경보전지역'
  }    
]

