// import styled from 'styled-components';
import styled from '@emotion/styled'

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

export const MainLeftPaneDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 440px;
  border: none;
  height: 90vh;  
  justify-content: flex-start;  
`;
interface FlexContainerProps {
  direction?: string;
  justifyContent?: string;
  alignItems?: string;
  width?: string;
  height?: string;
  background?: string;
  flex?: string;
  padding?: string;  
}

export const FlexContainer = styled.div<FlexContainerProps>`  
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  flex-wrap: wrap;
  justify-content: ${props => props.justifyContent || 'center'};
  align-items: ${props => props.alignItems || 'center'};
  width: ${props => props.width || '100%' };
  height: ${props => props.height || '100%'};
  background: ${props => props.background || '#fff'};
  padding: ${props => props.padding || '0px'};
`;
interface CommonProps {
  flex?: string;
  width?: string;
  height?: string;
}
export const KeyValueContainer = styled.div<FlexContainerProps>` 
  flex: ${props => props.flex || 'auto'};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: ${props => props.justifyContent || 'center'};
  align-items: ${props => props.alignItems || 'flex-start'};
  width: ${props => props.width || '100%' };
  height: ${props => props.height || '100%'};  
`;


export const LogoDiv = styled.div`  
  width: 90%;
  height: 22px;
  text-align: left;
  padding: 20px 20px 0px 20px;
  background: #B2DFDB; 
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #004D40;
`
export const HorizontalEmptyDivide = styled.div<CommonProps>`
  width: ${props => props.width || '100%' };
  height: ${props => props.height || '10px'};    
`;
export const VerticalEmptyDivide = styled.div<CommonProps>`
  height: ${props => props.height || '100%' };
  width: ${props => props.width || '10px'};    
`;
export const LogoPaneDiv = styled.div`
  position: absolute;
  width: 440px;  
  backgroud: #B2DFDB;
  height: 13vh;
  top: 0;
  bottom: 0;
`;

interface ScrollPanelProps {
  overflow?: boolean; 
}
export const MainPaneDiv = styled.div<ScrollPanelProps>`
  position: absolute;
  width: 440px;  
  backgroud: #B2DFDB;
  height: 77vh;
  top: 13vh;
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow-y:  ${props => props.overflow? 'auto': 'hidden'};
  &::-webkit-scrollbar {
    width: 5px;        
    display: block;
  }
  &::-webkit-scrollbar-thumb {
    background: #e9e9e9;
    opacity: .4;
  }
  &::-webkit-scrollbar-track {
    background: none;
  }
`;
export const RunPaneDiv = styled.div`
  position: absolute;
  width: 440px;
  height: 10vh;
  left: 0;
  bottom: 0;
`;