import React, { useEffect } from 'react';
// import '../css/MainComponent.scss';
import { MainContainer } from '../styledComponents/layout'
import MapContent from './MapContent';
import ProjectForm from './ProjectForm';
import ProgressLoading from './loading/ProgressLoading';
import { useDispatch } from 'react-redux';
import { sessionCheck } from '../modules/Auth';
// import { showInfoMessage } from '../modules/InformMessage';
import { useHistory } from "react-router-dom";
import ConfirmDialog from './common/ConfirmDialog'

export default function MainComponent() {

  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {    
    if(localStorage.getItem('sessionID') === null)  {
        history.push({
        pathname: "/login",     
      });
    } 
  }, [history, dispatch]);

  useEffect(() => {    
    dispatch(sessionCheck({ when: 'first'}));   // 끄고 나간 경우
    const interval = setInterval(()=> dispatch(sessionCheck()), 600000);
    return () => clearInterval(interval);
  }, [dispatch])

  return (
    <div>
      <ConfirmDialog />
      <ProgressLoading />
      <MainContainer>        
        <ProjectForm />
        <MapContent />
      </MainContainer>
    </div>
   
  )
}