import axios from 'axios';


const APIURL = '/api';
interface ProjectQuery {
  query: string,
  from?: number,
  size?: number,
  sort?: Array<any>
}

export const getParcelAddressAPI = (address: string) => {
  if (address.trim().length > 1) {
    return axios.post(`${APIURL}/GetParcelAddress`, {
      address: address
    });
    // return axios.post(`${APIURL}/SearchES`, {
    //   "table": 'parcel-info',
    //   "query": {
    //     "wildcard": {
    //       "주소.keyword": {
    //         "value": `*${address.trim()}*`,
    //       }
    //     }
    //   }    
    // });
  }
}


export const getProjectListAPI = (query: ProjectQuery) => {
  return axios.post(`${APIURL}/SearchES`, {
    ...query,
    table: "platform-buildit-project",
    query: "discarded:false AND deleted:false AND project_type: AI AND ( NOT hidden:true )",
  });
}

export const getParcelPnuAPI = (pnuList: Array<string>) => {
  console.log('getParcelInfo')
  if (pnuList!.length) {
    return axios.post(`${APIURL}/GetParcelPnu`, {
      pnuList: pnuList
    })
    // return axios.post(`${APIURL}/SearchES`, {
    //   table: 'parcel-info',
    //   query: `${pnuList!.map(r => `pnu: ${r}`).join(" OR ")}`,
    //   size: 1000,
    // });
  }
}


// export const getSearchAddressAPI = (address: string) => {
//   if (address.trim().length > 1) {
//     return axios.post(`${APIURL}/SearchES`, {
//       "table": 'parcel-info',
//       "query": {
//         "wildcard": {
//           "주소.keyword": {
//             "value": `*${address.trim()}*`,
//           }
//         }
//       }    
//     });
//   }
// }
