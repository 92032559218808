import { createAction, handleActions } from 'redux-actions';
import { call, put, take, race, takeEvery } from 'redux-saga/effects';
import { deleteProjectsAPI } from '../api/ProjectApi';
import { getProjectListAllinOneAPI } from '../api/ProjectApi';
import { createRequestActionTypes } from "../lib/createRequestSaga";
import { showErrorMessage } from './InformMessage';
import { sessionExpired } from './Auth';
import { setConfirmType, OK_CONFIRM, CANCEL_CONFIRM, initializeConfirm } from './Confirm'
const [
  GET_PROJECT_LIST,
  GET_PROJECT_LIST_SUCCESS,
  GET_PROJECT_LIST_FAIL,
] = createRequestActionTypes('GET_PROJECT_LIST');
const [
  DELETE_PROJECT,
] = createRequestActionTypes('DELETE_PROJECT_LIST');
const GET_PROJECT_TOTAL = 'GET_PROJECT_TOTAL';
const SET_SHOW_PROJECT_LIST = 'SET_SHOW_PROJECT_LIST';
const INITIALIZE_PROJECT_LIST = 'INITIALIZE_PROJECT_LIST';

export const getProjectList = createAction(GET_PROJECT_LIST);
export const deleteProject = createAction(DELETE_PROJECT);
export const getProjectTotal = createAction(GET_PROJECT_TOTAL);
export const setShowProjectList = createAction(SET_SHOW_PROJECT_LIST);
export const initializeProjectList = createAction(INITIALIZE_PROJECT_LIST);

function* getProjectListSaga(action: any) {
  
  try{
    const response = yield call(getProjectListAllinOneAPI, 
      { query: action.payload.query, size: action.payload.size, from: action.payload.from, sort: action.payload.sort });
    const listdata = response.data.hits!;

    yield put({
      type: GET_PROJECT_LIST_SUCCESS,
      payload: {listdata, total: response.data.total},
      meta: response,
    });
  } catch (e) {    
    if(e.response.status === 440) {
      yield put(sessionExpired());
      return;
    }
    yield put(showErrorMessage({
      msg: "프로젝트 목록 조회 실패",
      errorMessage: e.msg,
      autoClose: 3000,   
    }));
    yield put({
      type: GET_PROJECT_LIST_FAIL,
      payload: e.msg,
    });
  }
}

function* deleteProjectSaga(action: any) {
  const projectIDs = action.payload.projectIds;
  if(projectIDs.length === 0 ) return;

  yield put(initializeConfirm());   // 먼저 생성된 다이얼로그가 먼저 떠 있는 경우, 초기화를 실행하기 위해  
  yield put(setConfirmType({
    title: '프로젝트 삭제',
    children: '해당 프로젝트를 삭제하시겠습니까?'
  }));
  const { yes } = yield race({
    yes: take(OK_CONFIRM),
    no: take(CANCEL_CONFIRM)
  });  
  try {
    if(yes)
      yield call(deleteProjectsAPI, action.payload.projectIds);         
    else return;
  } catch(e) {
    yield put(showErrorMessage({
      msg: "프로젝트 삭제 실패",
      errorMessage: e.msg,
      autoClose: 3000,   
    }));
  }      
  yield put({
    type: GET_PROJECT_LIST,
    payload: action.payload.queryStr,
  });
}

export function* watchProjectList() {
  yield takeEvery(GET_PROJECT_LIST, getProjectListSaga);
  yield takeEvery(DELETE_PROJECT, deleteProjectSaga);
}

type state = {  
  data?: any;  
  total?: number;
  error?: string;
  loading: boolean;
  showList: boolean;
};

const initialState: state = {
  data: [],
  loading: false,
  total: 0,
  showList: false,
};
  
const projectList = handleActions<state, any> ( // success
  {    
    [GET_PROJECT_LIST]: (state, { payload }) => ({
      ...state, 
      loading: true,      
    }),
    [GET_PROJECT_LIST_SUCCESS]: (state, { payload }) => ({
      ...state, 
      loading: false,
      data: payload.listdata,
      total: payload.total,
    }),
    [GET_PROJECT_LIST_FAIL]: (state, { payload }) => ({
      ...state, 
      loading: false,
      data: [],
      total: 0,
    }),
    [SET_SHOW_PROJECT_LIST]: (state, { payload }) => ({
      ...state, 
      showList: payload.showList,
    }),
    [INITIALIZE_PROJECT_LIST]: () => initialState,
  },
  initialState,
)

export default projectList;
