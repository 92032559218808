import axios from 'axios';

const APIURL = '/api';

export const loginAPI = (userid: string, password: string) => {
  return axios.post(`${APIURL}/user/login`, {
    userid,
    password,
  });
}
// logoutAPI

export const logoutAPI = () => {
  return axios.post(`${APIURL}/user/logout`);
}

export const sessionCheckAPI = () => {  
  return axios.post(`${APIURL}/user/session`, {    
  });
}