import { useEffect, useState } from "react";
import MapManager from "../MapManager";
import { useSelector } from "react-redux";
import { RootState } from "../modules/Index";
import differenceBy from "lodash/differenceBy";
import { toGeom } from "../utils/Geometry";

// @ts-ignore
const { naver } = window;

export default function useSchoolMarkers(mapManager: MapManager) {
  const [schoolMarkers, setSchoolMarkers] = useState([]);
  const { searchResult } = useSelector(({ searchObject }: RootState) => ({
    searchResult: searchObject.data,
  }));

  const markerOverHandler = (marker: any) => {
    marker.setZIndex(300);
  };

  const markerOutHandler = (marker: any) => {
    marker.setZIndex(200);
  };

  const markerClickHandler = (marker: any) => {
    const position = marker.getPosition();
    mapManager.setCenter(position);
    mapManager.getMap().setZoom(19, false);
  };

  const makeSearchResultSchools = () => {
    const map = mapManager.getMap()?.getNaverMap();
    if (map == null) return;

    if (searchResult.schools.length) {
      const currentAllSchoolMarkers = [];
      const tempResult = [];
      const dup = {};
      searchResult.schools.forEach((item: any) => {
        const found = differenceBy(
          searchResult.schools,
          differenceBy(searchResult.schools, [item], "geom")
        );
        if (found.length > 1) {
          dup[item.geom] = found;
        }
      });

      Object.keys(dup).forEach((key: any) => {
        const value = dup[key];

        // console.log('value', value)
        const schoolContentString = '<div class="school_container">'
          .concat(
            value
              .map((item: any) => {
                return `           
             <div class="content_border_name">
               <div class="shcool_icon">
                 <img src="/img/school.png" style={{ width: '15px', height: '15px' }}>
               </div>
               ${item.name}
             </div>          
           `;
              })
              .join("")
          )
          .concat("</div>");
        const pointCenter = toGeom(
          value[0].geom,
          "Point"
        ).coordinates;

        const dupPointSchoolsMarker = new naver.maps.Marker({
          map: map,
          position: pointCenter,
          // visible: false,
          icon: {
            content: [schoolContentString].join(""),
          },
        });
        naver.maps.Event.addListener(dupPointSchoolsMarker, "mouseover", () => {
          markerOverHandler(dupPointSchoolsMarker);
        });

        naver.maps.Event.addListener(dupPointSchoolsMarker, "mouseout", () => {
          markerOutHandler(dupPointSchoolsMarker);
        });
        naver.maps.Event.addListener(dupPointSchoolsMarker, "click", () => {
          markerClickHandler(dupPointSchoolsMarker);
        });

        currentAllSchoolMarkers.push(dupPointSchoolsMarker);
        tempResult.push(...dup[key]);
      });

      const nonDuplicateSchoolMarkers = differenceBy(
        searchResult.schools,
        tempResult,
        "geom"
      ).map((item: any) => {
        const pointCenter = toGeom(
          item.geom,
          "Point"
        ).coordinates;
        const schoolName = item.name;
        const contentString = [
          `<div class="school_container">            
            <div class="content_name">
              <div class="school_icon">
                <img src="/img/school.png" style={{ width: '15px', height: '15px'  }}>
              </div>
              ${schoolName}
            </div>          
          </div>`,
        ].join("");

        const marker = new naver.maps.Marker({
          map: map,

          position: pointCenter,
          // visible: false,
          icon: {
            content: contentString,
            // size: new naver.maps.Size(80, 30),
            // anchor: new naver.maps.Point(0, 0)
          },
        });
        naver.maps.Event.addListener(marker, "mouseover", () => {
          markerOverHandler(marker);
        });
        naver.maps.Event.addListener(marker, "mouseout", () => {
          markerOutHandler(marker);
        });
        naver.maps.Event.addListener(marker, "click", () => {
          markerClickHandler(marker);
        });
        return marker;
      });

      currentAllSchoolMarkers.concat(nonDuplicateSchoolMarkers);

      if (currentAllSchoolMarkers!.length > 0)
        setSchoolMarkers(currentAllSchoolMarkers);
    }
  };

  useEffect(() => {
    schoolMarkers.forEach((marker: any) => {
      marker.setMap(null);
    });
    setSchoolMarkers([]);
    makeSearchResultSchools();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResult.schools]);
}
