import React, {useEffect, useRef, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";

import "../css/ProjectList.scss";
import { TextField } from "@material-ui/core";


const SearchForm = (props:any) => {
  
  const [inputVal, setInputVal] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  
  useEffect(() => {
    let debouncer = setTimeout(() => {
      props.handleChange(inputVal)
    }, 200);
    return () => {
      clearTimeout(debouncer);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputVal]);

  useEffect(() => {
    if (inputRef.current){
      inputRef.current.value = '';
    }
  }, [props.searchOption]);

  
  return (
    <div className=" search-options">
        <TextField
                className="use-district select search-form "
                select
                value={props.searchOption}
                SelectProps={{
                  native: true,
                }}
                variant="standard"
                onChange={
                    (e) => (props.handleSearchOption(e.target.value))
                }
        
              // inputProps={{ min: 10, style: { textAlign: 'left' } }}
              // InputLabelProps={{ shrink: true, }}

              color="primary"
              
            >
        <option value="project_name">프로젝트명</option>
        <option value="user_project_id">검토번호</option>
        </TextField>
      {/* <TextField
        className="use-district select"
        select
        value={props.searchOption}
        SelectProps={{
          native: true,
        }}
        variant="outlined"
        onChange={
          (e) => (props.handleSearchOption(e.target.value))
      }
      >
        <option value="project_name">프로젝트명</option>
        <option value="user_project_id">검토번호</option>
      </TextField> */}
      <TextField
        inputRef={inputRef}
        className="address-input"
        placeholder="검색어 입력"
        variant="standard"
        InputProps={{ endAdornment: <SearchIcon /> }}
        onChange={
          (e) => (
            setInputVal(e.target.value)
          )
        }
      />
    </div>
  );
};

export default SearchForm;
