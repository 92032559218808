import { createAction, handleActions } from 'redux-actions';


const SET_MAIN_SCROLLABLE = 'SET_MAIN_SCROLLABLE';
const INITIALIZE_INPUT_CONTROLS = 'INITIALIZE_INPUT_CONTROLS';

export const setMainScrollable = createAction(SET_MAIN_SCROLLABLE);

interface InputControlState {
  main_scrollable?: boolean;  
}

const initialState: InputControlState = {
  main_scrollable: true,  
};
  
const inputcontrols = handleActions<InputControlState, any>(
  {
    [SET_MAIN_SCROLLABLE]: (state, { payload }) => ({
      ...state,
      main_scrollable: payload.scrollable,
    }),  
    [INITIALIZE_INPUT_CONTROLS]: () => initialState,
  },
  initialState,
);

export default inputcontrols;