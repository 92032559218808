import { useEffect } from 'react';
import { useSelector, useDispatch  } from 'react-redux';
// import { toast } from 'react-toastify';
import { closeErrorMessage, closeSuccessMessage, closeInfoMessage, closeWarningMessage } from "../../modules/InformMessage";
import { RootState } from '../../modules/Index';
import { useSnackbar } from 'notistack';

export const InformMessage = () => {

  const { enqueueSnackbar } = useSnackbar();
  const { error, info, success, warning } = useSelector(
    ({ informMessage }: RootState) => ({
      error: informMessage.error,
      info: informMessage.info,
      success: informMessage.success,
      warning: informMessage.warning,
    }),
  );
  const dispatch = useDispatch();
  
  useEffect(() => {
    
    if(error && error.show) {
      enqueueSnackbar(error.msg, { 
        variant: 'error',
        autoHideDuration: error.autoClose,
        preventDuplicate: true,
        onClose: () => dispatch(closeErrorMessage()),
        style: {
          width: `${error.width}px`,
          zIndex: 10000,
        }
      });
      // toast.error(error.msg, {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: error.autoClose,
      //   onClose: () => dispatch(closeErrorMessage()),
      //   style: {
      //     width: `${error.width}px`
      //   }
      // });
    }
    if(success && success.show) {
      enqueueSnackbar(success.msg, { 
        variant: 'success',
        autoHideDuration: success.autoClose,
        preventDuplicate: true,
        onClose: () => dispatch(closeSuccessMessage()),
        style: {
          width: `${success.width}px`,
          zIndex: 10000,
        }
      });
      // toast.success(success.msg, {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: success.autoClose,
      //   onClose: () => dispatch(closeSuccessMessage()),
      //   style: {
      //     width: `${success.width}px`
      //   }
      // });
    }
    if(info && info.show) {
      enqueueSnackbar(info.msg, { 
        variant: 'info',
        autoHideDuration: info.autoClose,
        preventDuplicate: true,
        onClose: () => dispatch(closeInfoMessage()),
        style: {
          width: `${info.width}px`,
          zIndex: 10000,
        }
      });
      // toast.info(info.msg, {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: info.autoClose,
      //   onClose: () => dispatch(closeInfoMessage()),
      //   style: {
      //     width: `${info.width}px`
      //   }
      // });
    }
    if(warning && warning.show) {
      enqueueSnackbar(warning.msg, { 
        variant: 'warning',
        autoHideDuration: warning.autoClose,
        preventDuplicate: true,
        onClose: () => dispatch(closeWarningMessage()),
        style: {
          width: `${warning.width}px`,
          zIndex: 10000,
        }
      });
      // toast.warning(warning.msg, {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: warning.autoClose,
      //   onClose: () => dispatch(closeWarningMessage()),
      //   style: {
      //     width: `${warning.width}px`
      //   }
      // });
    }
  }, [error, success, info, warning, dispatch, enqueueSnackbar]);
  return (
   <div></div>
  )
}

export default InformMessage;