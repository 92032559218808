
import React, { useRef, useState } from 'react';
import SiteAnalysis from './SiteAnalysis';
import '../../css/ResultInfo.scss';
import ArchitectureOverview from './ArchitectureOverview';
import ReactToPrint from 'react-to-print';
import FloorDivision from './FloorDivision';
import { Button } from '@material-ui/core';

import ResultOverview from './ResultOverview';

import { useSelector } from 'react-redux';
import { RootState } from '../../modules/Index';

export interface ResultPageProps {
  open?: boolean,
  setUnitState?: Function,
  unitState?: string, // ㎡ | 평
  address?: string,

}

const ResultInfo = () => {

  const { resultInfoData } = useSelector(
    ({ selectedProject }: RootState) => ({
      resultInfoData: selectedProject.result,      
    }),
  );
  const componentRef = useRef(null);
  const [unitState, setUnitState] = useState('㎡');
  // const [open, setOpen] = useState(true); 
  const convertUnit = () => { // 단위 변환
    if (unitState === "㎡") {
      setUnitState('평');
    } else {
      setUnitState('㎡');
    }
  }
  const { address } = useSelector(
    ({ selectedProject }: RootState) => ({
      address: selectedProject.project.주소목록,
    }),
  );

  return (
    <>
      <ReactToPrint
        // onBeforeGetContent={toggleAddressList}
        // onAfterPrint={() => setOpen(true)}
        trigger={() => <Button className="btn btn-primary btn-outline print-btn">인쇄</Button>}
        content={() => componentRef.current
        }
        documentTitle={address && address.length === 1 ? `${address[0]}` : `${address[0]}외 ${address.length - 1}필지`}
      />
      <div className="ResultInfo" >  
        <div className="unit-btns"> 
          <button className={`btn btn-primary btn-outline btn-unit ${unitState === "㎡" ? 'active' : ''}`} onClick={convertUnit}>㎡</button>
          <button className={`btn btn-primary btn-outline btn-unit ${unitState === "평" ? 'active' : ''}`} onClick={convertUnit}>평</button>
        </div>
        <Button 
            className="download-btn"
            onClick={(e) => {
              console.log(`builditsimhost://run?code=${resultInfoData.designer_code}&email=${resultInfoData!.email}`)
              window.location.href = `builditsimhosp://run?code=${resultInfoData.designer_code}&email=${resultInfoData!.email}`;
            }}
          >
          <div>DESIGNER</div>
          <span style={{textTransform: "none"}}>.exe</span>
        </Button>
        <div className="print-wrap" ref={componentRef}>
          <ResultOverview unitState={unitState}></ResultOverview>
          <SiteAnalysis unitState={unitState} setUnitState={setUnitState}></SiteAnalysis>
          <ArchitectureOverview unitState={unitState} setUnitState={setUnitState}></ArchitectureOverview>
          <FloorDivision></FloorDivision>
          {/* <FloorHouseholdSummaryWrapper unitState={unitState} setUnitState={setUnitState}></FloorHouseholdSummaryWrapper> */}
          {/* <BusinessFeasibility unitState={unitState} ></BusinessFeasibility>           */}
        </div>

      </div>
      
    </>
  );
};
export default ResultInfo;