
import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import project, { watchProject } from './Project';
import addressInfo, { watchAddressInfo } from './AddressInfo';
import fieldInfo, { watchFieldInfo } from './FieldInfo';
// import priceInfo, { watchPriceInfo } from './PriceInfo';
import parcelInfo, { watchParcelInfo } from './ParcelInfo';
import projectDefault, { watchProjectDefault } from './ProjectDefault';
import jiguByArea, { watchJiguByArea } from './JiguByArea';
import projectErrors, { watchProjectErrors } from './ProjectErrors';
import selectedProject, { watchResultInfo } from './SelectedProject';

import informMessage from './InformMessage';
import auth, { watchAuth } from './Auth';
import loading from './loading';
import projectList, {watchProjectList} from './ProjectList';
import confirm from './Confirm';

import inputcontrols from './InputControls';
import searchObject, { watchSearchObject } from './SearchObject'


const rootReducer = combineReducers({
  project,
  loading,
  addressInfo,
  fieldInfo,
  parcelInfo,
  projectDefault,
  jiguByArea,
  projectErrors,
  selectedProject,  
  projectList,  
  informMessage,
  auth,
  confirm,
  inputcontrols,
  searchObject
});

export function* rootSaga() {
  yield all([
    watchProject(),
    watchFieldInfo(),
    watchParcelInfo(),
    watchProjectDefault(),
    watchJiguByArea(),
    watchResultInfo(),
    watchProjectList(),
    // watchPriceInfo(),    
    watchProjectErrors(),
    watchAuth(),
    watchSearchObject(),
    watchAddressInfo(),
  ]);
}

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;