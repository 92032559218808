import React from 'react';
import { useSelector, useDispatch  } from 'react-redux';
import { runProject } from '../../modules/Project';
import { RootState } from '../../modules/Index';
import { FlexContainer } from '../../styledComponents/layout'
import { RunButton } from '../../styledComponents/buttons';

export const RunComponent = () =>{
  
  const { canRunProject, pnuList, defaultloading, loading, fieldSelectMode } = useSelector(
    ({ project, projectErrors, projectDefault }: RootState) => ({       
      pnuList: project.pnuList,
      canRunProject: projectErrors.canRunProject,    
      defaultloading: projectDefault.loading,  
      loading: project.projectLoading,   
      fieldSelectMode: project.data.project_site_type
    }),
  );
  const runCurrentProject = () => {
    if(canRunProject && pnuList!.length > 0) dispatch(runProject());
    else if(canRunProject && fieldSelectMode === 'DRAW') dispatch(runProject());
  }
  
  const dispatch = useDispatch();

  return (
    <FlexContainer
      direction="column"
      width="100%"
      justifyContent="center"     
      height="auto"      
      style={{ borderTop: '1px solid #e9e9e9', paddingTop: '10px'}}     
    >    
      <RunButton runnable={canRunProject && !defaultloading && !loading} onClick={runCurrentProject}>음압병동 검토하기</RunButton>   
    </FlexContainer>
  )

}

export default RunComponent;