import React from 'react';

import { useSelector, useDispatch  } from 'react-redux';
import { RootState } from '../../modules/Index';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { cancelConfirm, okConfirm } from '../../modules/Confirm';
import { TitleText, ContentText, ButtonStyle } from '../../styledComponents/dialog';

const ConfirmDialog = () => {
  const { title, children, open } = useSelector(
    ({ confirm }: RootState) => confirm);
  const dispatch = useDispatch();
  return (
    <Dialog
      open={open}
      onClose={() => dispatch(cancelConfirm())}
      aria-labelledby="confirm-dialog"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="confirm-dialog"><TitleText>{title}</TitleText></DialogTitle>
      <DialogContent><ContentText>{children}</ContentText></DialogContent>
      <DialogActions>
        <Button
          style={ButtonStyle}
          onClick={() => dispatch(cancelConfirm())}
          color="default"
        >
          아니오
        </Button>
        <Button
          style={ButtonStyle}
          onClick={() => {
            dispatch(okConfirm())
          }}
          color="default"
        >
          네
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;