import React, { useEffect } from "react";
import "../../css/ProjectResult.scss";
import { IconButton } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../modules/Index";
import ResultInfo from "./ResultInfo";
import { HourglassEmpty, Undo } from "@material-ui/icons";
import { Project } from "../../model/Project";
import {
  setProjectSelect,
  getProjectResult,
  getProjectFailReason,
} from "../../modules/SelectedProject";
import FailedResult from "./FailedResult";
import CopyableLabel from "../CopyableLabel";

interface ProjectResultProps {
  onClose: () => void;
  onBack: () => void;
  curProject: Project;
}

//export const ProjectResultContext = createContext<any>({});

export default function ProjectResult(props: ProjectResultProps) {
  const { currentProject, resultInfoData } = useSelector(
    ({ selectedProject }: RootState) => ({
      resultInfoData: selectedProject.result,
      currentProject: selectedProject.project,
    })
  );

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("props.curProject", props.curProject);
    dispatch(setProjectSelect(props.curProject));
    if (props.curProject!.status === "FINISHED") {
      dispatch(
        getProjectResult({
          project: props.curProject,
        })
      );
    }
    if (props.curProject!.status === "FAILED") {
      dispatch(
        getProjectFailReason({
          project: props.curProject,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.curProject]);

  const renderCurrentProject = (status: string) => {
    switch (status) {
      case "FAILED" /* 결과 - 확인요 */:
        return <FailedResult />;
      case "WAITING":
        return (
          <>
            <p className="failed-description">
              <HourglassEmpty />
              &nbsp; 프로젝트가 분석중입니다
            </p>
          </>
        );
      case "RUNNING":
        return (
          <>
            <p className="failed-description">
              <HourglassEmpty />
              &nbsp; 프로젝트가 분석중입니다
            </p>
          </>
        );
      case "FINISHED":
        return (
          currentProject &&
          Object.keys(resultInfoData).length > 0 && (
            // <ProjectResultContext.Provider value={{ project: currentProject, result: resultInfoData }}>
            <ResultInfo></ResultInfo>
          )
        );
      default:
        return "페이지 오류";
    }
  };

  return (
    <div className="ProjectResult">
      <header>
        <div className="title-wrap">
          {(resultInfoData && resultInfoData.주소목록 && (
            <div className="title">
              {resultInfoData.주소목록.length >= 2
                ? `${resultInfoData.project_address} 외 ${
                    resultInfoData.주소목록.length - 1
                  }필지`
                : resultInfoData.project_address}
            </div>
          )) ||
            // <div></div>
            (currentProject && (
              <div className="title">
                {currentProject.주소목록 && currentProject.주소목록.length >= 2
                  ? `${currentProject.주소목록[0]} 외 ${
                      currentProject.주소목록.length - 1
                    }필지`
                  : currentProject.주소목록}
              </div>
            ))}
          <div className="btn-wrap not-print">
            <IconButton className={`back-btn`} onClick={() => props.onBack()}>
              <Undo />
            </IconButton>
            {/* <IconButton className="btn" onClick={() => props.onClose()}>
            <CloseIcon />
          </IconButton> */}
          </div>
        </div>
        <div className="sub-title">결과 레포트</div>
        {
          resultInfoData && resultInfoData.real_report_number && (
                <CopyableLabel id="copy_project_id" tooltipMsg="프로젝트_레포트 번호 복사하기" label={`${currentProject.project_id}_${resultInfoData?.real_report_number}`} />
            )
        }
      </header>
      {currentProject && renderCurrentProject(currentProject.status)}
    </div>
  );
}
