import React from 'react';
import { FlexContainer, HorizontalEmptyDivide } from '../../styledComponents/layout';
import { RowsKeyValue } from '../common/RowsKeyValue'
import { RootState } from '../../modules/Index';
import { useSelector} from 'react-redux';
import { SectionTitleText } from '../../styledComponents/text';

export const DrawingInfoSection = ({...props}) => {
  const { projectData } = useSelector(
 
    ({ project }: RootState) => ({
      projectData: project.data,    
    }),
  );

  return (
    <div style={{ display: 'flex', width: '100%', height: 'auto', marginTop: '10px' }}>
    {
      <FlexContainer
        direction="column"
        height="auto"
        width="90%"
        justifyContent="flex-start"         
        style={{ 
          border: '1px solid #26A69A',
          borderRadius: '10px',
          marginLeft: '20px',
          paddingBottom: '10px'
        }} 
      >
        <HorizontalEmptyDivide />      
        <SectionTitleText width="100%" align="left" padding="0px 0px 10px 20px">선택한 필지</SectionTitleText>
        
          
        <FlexContainer width="94%" height="60px" justifyContent="flex-start" alignItems="flex-start">
          <RowsKeyValue width="92%" keyName="주소" value={projectData.project_address}  />  
        </FlexContainer>
        
        <HorizontalEmptyDivide />
        <FlexContainer width="94%" height="60px" justifyContent="flex-start" alignItems="flex-start">
          <RowsKeyValue keyName="대지면적" value={Number(projectData.project_site_area).toFixed(2)} width="25%" unit="m2" />
          <RowsKeyValue keyName="지목" value={projectData.project_jimog} width="20%" />          
        </FlexContainer>          
        <FlexContainer  width="94%"  height="auto" alignItems="center">
          <RowsKeyValue keyName="용도지역" value={projectData.project_use_district} width="40%" />
          {/* <Button
            style={{
              width: '45%',
              border: '1px solid #00897B',
              color: '#00897B',
            }}
            className="btn btn-primary btn-outline project-list-btn"
            onClick={() => showLandUse()}
          > 
          토지이용계획
          </Button> */}
        </FlexContainer>
        <HorizontalEmptyDivide />
        {/* {
          open && (   
                    
            <FlexContainer width="96%" height="auto">
              <InfoTitleText width="98%">토지이용계획</InfoTitleText>
              <HorizontalEmptyDivide />
              <RowsKeyValue keyName="국토의 계획 및 이용에 관한 법률」에 따른 지역ㆍ지구등" value={landUse} width="100%" />
              <HorizontalEmptyDivide />
              <RowsKeyValue keyName="다른 법령 등에 따른 지역ㆍ지구등" value={etcUse} width="100%" />
              <HorizontalEmptyDivide />
            </FlexContainer>
          )
        } */}
      </FlexContainer>
      
    }
    </div>
    
    
  )
}

export default DrawingInfoSection;