import styled from '@emotion/styled'

export const CircularLoading = styled.div`  
  height: 100%;
  width: 100%;
  background-color: rgba(200, 200, 200, 0.3);
  position: absolute;
  top: 0px;
  z-index: 999999;
  pointer-events: none;
  display: flex;
  flex-flow: column;
`;
export const CircularMain = styled.div`
  position:absolute;
  top: 50px; left: 50px;
  bottom: 50px; right: 50px;
  z-index: 1000;
`
export const CircularProgress =  styled(CircularMain)`  
  width: 244px;
  height: 244px;
  margin: auto;
  color: white;  
  background-image: url('img/loading.png');
  animation: circle 1s linear infinite;
  text-align: center;
  vertical-align: middle;  
  @keyframes circle{
    0% {
      transform:rotate(0deg);
    }
    100% {
      transform:rotate(360deg);                
    }
  }
`

export const CircularMainText = styled(CircularMain)`  
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  width: 244px;
  height: 30px;
  margin: auto;
  color: white;  
  text-align: center;
  vertical-align: middle;  
`;
