import { createAction, handleActions } from 'redux-actions';
import  { createRequestActionTypes } from '../lib/createRequestSaga';
import { RootState } from './Index';
import { getProjectDefaultAPI } from '../api/ProjectApi';
import { setDefaultErrors, initializeProjectDefaultErrors } from './ProjectErrors'
import { takeLatest, put, select, call } from 'redux-saga/effects';
import { Project, ProjectUseDistrict, 프로젝트_기본값, BuildingType } from '../model/Project';
import { parseProjectDefaultParser } from './lib/ProjectDefaultParser'
import { modifyProject } from './Project';
import { sessionExpired } from './Auth';
import { simInputValidCheck } from '../Constraints';

import { showErrorMessage } from './InformMessage';
import { getParcelAddress } from './ParcelInfo';
// import { default as _ } from 'lodash';
const INITIALIZE_PROJECT_DEFAULT = 'INITIALIZE_PROJECT_DEFAULT';

const [
  GET_PROJECT_DEFAULT,
  GET_PROJECT_DEFAULT_SUCCESS,
  GET_PROJECT_DEFAULT_FAILURE,
] = createRequestActionTypes('GET_PROJECT_DEFAULT');

const UPDATE_BUILDING_TYPE = 'UPDATE_BUILDING_TYPE';
const UPDATE_BUILDING_TYPE_SUCCESS = 'UPDATE_BUILDING_TYPE_SUCCESS';

export const getProjectDefault = createAction(
  GET_PROJECT_DEFAULT,
);
export const updateBuildingType = createAction(UPDATE_BUILDING_TYPE);

export const initializeProjectDefault = createAction(INITIALIZE_PROJECT_DEFAULT);

function* getProjectDefaultSaga(action: any) {   
  try {
    const response = yield call(getProjectDefaultAPI, action.payload.project_site,  action.payload.use_district);
    
    const currentProject = yield select((state: RootState) => state.project.data);
    const fieldSelectMode = yield select((state: RootState) => state.project.data.project_site_type);
    const defaultData = response.data;
    let building_type = "오피스텔" as BuildingType;

    // console.log('building_type', building_type);
    const parsingDefaultData = yield call(parseProjectDefaultParser, currentProject, defaultData, building_type, action.payload.set_use_distirct);    // args : undefined
    let resultDefault = parsingDefaultData;
    // console.log('resultDefault', resultDefault)
    if(action.payload.use_district) {     
      resultDefault = {       // projectDefault 파싱된 데이터 project에 저장
        ...parsingDefaultData.resultState!.projectDefault,
        project_use_district: action.payload.use_district,             
      }
    } else {
      resultDefault = {       
        ...parsingDefaultData.resultState!.projectDefault,
      };
    }
    yield put(modifyProject( {
      ...resultDefault,
      building_stories_max: 2,
      building_stories_min: 2,
      building_stories_avg: 2,
    }));
    if(fieldSelectMode === 'DRAW') {
      yield put(getParcelAddress({ 
        address: defaultData.주소
      }));
    }
    yield put({                     // projectDefault 저장
      type: GET_PROJECT_DEFAULT_SUCCESS,
      payload: { 
        ...defaultData,
        parsedProjectDefault: resultDefault,
      },
      meta: response,
    });
    
    const projectData = yield select((state: RootState) => state.project.data);
    if(projectData.project_site.length === 0) {   // 데이터 받고 오는 중에 프로젝트 필지가 초기화 된 경우. 클릭으로 필지 해제 등. 
      yield put(initializeProjectDefault());
      yield put(initializeProjectDefaultErrors());      
      return;
    }
    const projectDefaultData = yield select((state: RootState) => state.projectDefault.data);
    // console.log('projectDefaultData', projectDefaultData)
    const defaultErrors = yield select((state: RootState) => state.projectErrors.defaultErrors);
    const projectErrors = projectDefaultData !== undefined ? simInputValidCheck(projectData) : {errs : defaultErrors};
    
    if(Object.keys(projectErrors.errs).length)  {
      yield put(showErrorMessage({
        msg: "해당 필지 기본 설정 실패",        
        autoClose: 3000,   
      }));
      yield put(setDefaultErrors(projectErrors.errs));     // projectErros state에 해당 error 정보를 업데이트 해둔다.
    } else {
      yield put(initializeProjectDefaultErrors());
    }   
  } catch (e) {
    
    if(e.response.status === 440) {
      yield put(sessionExpired());
      return;
    }
    yield put(showErrorMessage({
      msg: "해당 필지 기본 설정 정보 수신 실패",
      errorMessage: e.msg,
      autoClose: 3000,   
    }));
    yield put({
      type: GET_PROJECT_DEFAULT_FAILURE,
      payload: e.msg,
    });
  }
}

function* updateBuildingTypeSaga(action: any) {       // 프로젝트 default 정보를 불러와야 하는 경우. project_site가 변경되는 경우, 
  const building_type = action.payload.building_type;
  const projectData = yield select((state: RootState) => state.project.data); 
  const defaultData = yield select((state: RootState) => state.projectDefault.data); 
  const parsingDefaultData = yield call(parseProjectDefaultParser, projectData, defaultData, building_type, action.payload.set_use_distirct);    // args : undefined
  
  yield put(modifyProject({       
    ...parsingDefaultData.resultState!.projectDefault,
  }));
  yield put({                     // projectDefault 저장
    type: UPDATE_BUILDING_TYPE_SUCCESS,
    payload: parsingDefaultData.resultState!.projectDefault    
  });
}

export function* watchProjectDefault() { 
  yield takeLatest(GET_PROJECT_DEFAULT, getProjectDefaultSaga);
  yield takeLatest(UPDATE_BUILDING_TYPE, updateBuildingTypeSaga);
}
export interface 표기_기본값 extends 프로젝트_기본값 {
  용도지역?: ProjectUseDistrict,
  건물종류?: BuildingType,
  층고?: number,
}

export interface ProjectData extends Project {  
}

type ProjectDefaultState = {  
  data?: any;
  error?: string;  
  loading: boolean;
};

const initialState: ProjectDefaultState = { 
  data: {},
  loading: false,
};
  
const projectDefault = handleActions<ProjectDefaultState, any>(
  {   
    [GET_PROJECT_DEFAULT]: (state, { payload }) => ({
      ...state,
      loading: true,
    }),
    [GET_PROJECT_DEFAULT_SUCCESS]: (state, { payload }) => ({
      ...state,
      data: payload,
      loading: false,
    }),
    [GET_PROJECT_DEFAULT_FAILURE]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),    
    [UPDATE_BUILDING_TYPE_SUCCESS]: (state, { payload }) => ({       // project_use_district가 변경될때, projectDefault 갱신을 위한 Action 분리.
      ...state,
      data: { 
        ...state.data,        
        parsedProjectDefault: payload,
      }
    }),
    [INITIALIZE_PROJECT_DEFAULT]: () => initialState,
  },
  
  initialState,
);

export default projectDefault;