import React, { useEffect, useState } from 'react';
import { Route, Switch } from "react-router-dom";
import './App.css';
import './css/TemplateUI.scss';
import 'typeface-roboto';
import MainComponent from './components/MainComponent'
import Login from './components/auth/Login';
import InformMessage from './components/message/InformMessage';
// @ts-ignore
import { SnackbarProvider } from 'notistack';

export const ENV = window.location.host.split('.')[0];
export const stage: string = ENV === "www" ? "prod" : 'dev';
// static stage: string = "prod"; 


function App() {
  const [mapLoaded, setMapLoaded] = useState(false);
  
  useEffect(() => {
    loadMap();
  }, []);

  const loadMap = async () => {
    while(true) {      
      try {
        // @ts-ignore
        if (window.naver.maps.NaverStyleMapTypeOptions) {
          setMapLoaded(true)
          break;
        } else {
          throw new Error('no naver map loadded');
        }
      } catch (e) {
        await new Promise(resolve => setTimeout(resolve, 100))
      }
    }
  }

  if (mapLoaded) {
    return (
      <div className="App">        
        <SnackbarProvider        
          anchorOrigin={{ vertical: "top", horizontal: "center" }}         
          maxSnack={3}          
        >         
          <Switch>
            <Route path="/" exact component={MainComponent} />          
            <Route path="/login" component={Login} />  
          </Switch>       
          <InformMessage />
        </SnackbarProvider>
      </div>
    );
  } else {    
    return (
      <div className="App">

      </div>
    )
  }
}

export default App;
