
/*eslint array-callback-return: "off"*/
import React, { useEffect, useRef, useState } from 'react';
// import { default as _ } from 'lodash';
import ProjectResultTitle from './ProjectResultTitle';
import { useSelector } from 'react-redux';
import { RootState } from '../../modules/Index';

const FloorDivision = () => {
  const { resultInfoData } = useSelector(
    ({ selectedProject }: RootState) => ({
      resultInfoData: selectedProject.result,
    }),
  );
  const resImgLength = resultInfoData.resImages.length; // 결과이미지 수
  const pageRef = useRef(1);
  const [pageIndex, setPageIndex] = useState([] as Array<Array<number>>);
  //  const [floorDescript, setFloorDescript] = useState<Array<string>>([]);
  const DIVISION_NUM = 2; // 페이지 분할할 이미지 수

  useEffect(() => {
    pageRef.current = Math.ceil(resImgLength / DIVISION_NUM);
    let page_index: Array<Array<number>> = [...pageIndex]; // _.cloneDeep(pageIndex);
    let tempNumber = 0;
    let startIndex = 0;
    let endIndex = DIVISION_NUM - 1;
    for (let i = 0; i < pageRef.current; i++) {
      page_index.push([startIndex, endIndex]);
      tempNumber += DIVISION_NUM;
      startIndex = tempNumber;
      endIndex = startIndex + DIVISION_NUM - 1;
    }
    setPageIndex(page_index);

    /* 사진 설명 */
    // const imgFloor = resultInfoData.resImages.map((url: string) => {
    //   const floor = /image_\d*.png/.exec(url)![0].replace(/[^0-9]/g , "");
    //   return Number(floor);
    // })

    // imgFloor.forEach((floor:number, idx:number) => {
    //   if (floor === 1){
    //     floorDescript.push('배치도')

    //   }
    //   else if (idx === imgFloor.length - 1) { // 마지막 이미지
    //     const maxFloor = resultInfoData.loc_building_stories_min;
    //     floorDescript.push(
    //       floor ===  maxFloor ? floor + '층 평면도' : floor + '~' + (maxFloor) + '층 평면도')
    //   }
    //   else {
    //     const limitFloor = imgFloor[idx+1] - 1
    //     floorDescript.push(
    //       floor === limitFloor ? floor + '층 평면도' : floor + '~' + (imgFloor[idx+1] - 1) + '층 평면도')
    //   }
    // });    
    // setFloorDescript(floorDescript)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {
        pageIndex.map((page: Array<number>, index: number) =>
          <section className="FloorDivision" key={page[0] + Math.random()}>
            <ProjectResultTitle></ProjectResultTitle>
            <div className={`sub-title`}>{`4-${index + 1}. 층별 평면도`}</div>
            <div className="content-wraper">{
              resultInfoData.resImages.map((url: string, index: number) => {
                if (index >= page[0] && index <= page[1]) {
                  return <article key={url}><img className="result-img" src={url} alt="결과 이미지"></img>
                  </article>
                }
              })}</div>
          </section>
        )
      }
    </>
  );
}

export default FloorDivision;