// import { IconButton } from '@material-ui/core';
// import { Undo } from '@material-ui/icons';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../modules/Index';


const ProjectResultTitle = () => {
  
  const { resultInfoData, address } = useSelector(
    ({ selectedProject }: RootState) => ({
      resultInfoData: selectedProject.result,
      address: selectedProject.project.주소목록,
    }),
  );

  const addressTitle = useMemo(() => {
    if (resultInfoData.주소목록){ 
      return resultInfoData.주소목록 && resultInfoData.주소목록.length >= 2 ? `${resultInfoData.주소목록[0]} 외 ${address.length - 1}필지` : resultInfoData.project_address
    }
    else {
      return address && address.length >= 2 ? `${address[0]}외 ${address.length - 1}필지` : address[0];
    }
  }, [resultInfoData, address]);

  return (
    <header className="print-only">
      <div className="title-wrap">
        <div className="title">{addressTitle}</div>
      </div>
      <div className="sub-title-header">결과 레포트</div>   
    </header>
  );
};

export default ProjectResultTitle;