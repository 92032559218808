import { createAction, handleActions } from "redux-actions";
import { createRequestActionTypes } from "../lib/createRequestSaga";
import { RootState } from "./Index";
import { createProjectAPI } from "../api/ProjectApi";
import { generateRoadLineAPI, getRoadSiteAPI } from "../api/GeneratorApi";
import { getJiguByArea, initializeJiguByArea } from "./JiguByArea";
import { getParcelPnu, initializeParcelInfo } from "./ParcelInfo";
import { getProjectDefault, initializeProjectDefault } from "./ProjectDefault";
import { initializeFieldInfo } from "./FieldInfo";
import { showErrorMessage, showWarningMessage } from "./InformMessage";
import {
  setDefaultErrors,
  initializeProjectDefaultErrors,
  initializeProjectErrors,
  setRoadGeneratorErrors,
  setRoadGeneratorLoading,
} from "./ProjectErrors";
import {
  put,
  select,
  call,
  takeEvery,
  retry,
  race,
  take,
  takeLatest,
} from "redux-saga/effects";
import { Project, ShapeType } from "../model/Project";
import { simInputValidCheck } from "../Constraints";
import { sessionExpired } from "./Auth";
import {
  setConfirmType,
  OK_CONFIRM,
  CANCEL_CONFIRM,
  initializeConfirm,
} from "./Confirm";

type ProjectState = {
  data: ProjectData;
  pnuList: Array<string>;
  saveResult?: any;
  genRoad?: any;
  projectLoading: boolean;
  deletePolygon?: boolean;
  error?: string;
  shapeType: ShapeType;
  drawShape: boolean;
};

const [CREATE_PROJECT, CREATE_PROJECT_SUCCESS, CREATE_PROJECT_FAILURE] =
  createRequestActionTypes("CREATE_PROJECT");

const [UPDATE_PROJECT, UPDATE_PROJECT_SUCCESS, UPDATE_PROJECT_FAILURE] =
  createRequestActionTypes("UPDATE_PROJECT");

const [
  GENERATE_ROAD_LINE,
  GENERATE_ROAD_LINE_SUCCESS,
  GENERATE_ROAD_LINE_FAILURE,
] = createRequestActionTypes("GENERATE_ROAD_LINE");

const INITIALIZE_PROJECT = "INITIALIZE_PROJECT";
const MODIFY_PROJECT = "MODIFY_PROJECT";
const MODIFY_PNU_LIST = "MODIFY_PNU_LIST";
const MODIFY_PROJECT_CONFIG = "MODIFY_PROJECT_CONFIG";
// const SET_PROJECT_RUNNABLE = "SET_PROJECT_RUNNABLE";
const UPDATE_USE_DISTRICT = "UPDATE_USE_DISTRICT";

const RUN_PROJECT = "RUN_PROJECT";

// const SET_PURCHASE_CONDITION = 'SET_PURCHASE_CONDITION';

const SET_SUPPORT_PAY = "SET_SUPPORT_PAY";

const CHANGE_SELECT_MODE = "CHANGE_SELECT_MODE";
const CHANGE_SHAPE_TYPE = "CHANGE_SHAPE_TYPE";
const CHANGE_DRAW_SHAPE = "CHANGE_DRAW_SHAPE";

const GET_ROAD_SITE = "GET_ROAD_SITE";
const GET_ROAD_SITE_SUCCESS = "GET_ROAD_SITE_SUCCESS";

const DELETE_POLYGON = "DELETE_POLYGON";
const SET_DELETE_POLYGON = "SET_DELETE_POLYGON";

const INITIALIZE_PROJECT_WITH_MODE = "INITIALIZE_PROJECT_WITH_MODE";

export const createProject = createAction(CREATE_PROJECT);
export const updateProject = createAction(UPDATE_PROJECT);
export const initializeProject = createAction(INITIALIZE_PROJECT);
export const modifyProject = createAction(MODIFY_PROJECT); // 해당 Action 처리시 saga effect -> pnu Parcel 정보 불러오기.
export const modifyPnuList = createAction(MODIFY_PNU_LIST);
// export const setProjectRunnable = createAction(SET_PROJECT_RUNNABLE);
export const generateRoadLine = createAction(GENERATE_ROAD_LINE);
export const updateUseDistrict = createAction(UPDATE_USE_DISTRICT);

export const setSupportPay = createAction(SET_SUPPORT_PAY);
export const runProject = createAction(RUN_PROJECT);
export const modifyProjectConfig = createAction(MODIFY_PROJECT_CONFIG);
// export const setPurchaseCondition = createAction(SET_PURCHASE_CONDITION);

export const changeSelectMode = createAction(CHANGE_SELECT_MODE);
export const changeShapeType = createAction(CHANGE_SHAPE_TYPE);
export const changeDrawShape = createAction(CHANGE_DRAW_SHAPE);
export const getRoadSite = createAction(GET_ROAD_SITE);
export const deletePolygon = createAction(DELETE_POLYGON);
export const setDeletePolygon = createAction(SET_DELETE_POLYGON);
export const initializeProjectWithMode = createAction(
  INITIALIZE_PROJECT_WITH_MODE
);

function* changeSelectModeSaga(action: any) {
  yield put(initializeProjectDefault());
  yield put(initializeProject());
  yield put(initializeProjectErrors());
  yield put(initializeJiguByArea());
  yield put(initializeFieldInfo());
  yield put(initializeParcelInfo());
  yield put(initializeProjectDefaultErrors());
  yield put(
    initializeProjectWithMode({
      project_site_type: action.payload.mode,
    })
  );
}

function* changeShapeTypeSaga(action: any) {
  const type = action.payload.shapeType as ShapeType;

  if (type === "PROJECT_SITE") {
    yield put(changeDrawShape({ drawShape: true }));
  } else if (type === "VACANCY_INSIDE") {
    yield put(changeDrawShape({ drawShape: false }));
  }
}

function* getRoadSiteSaga(action: any) {
  try {
    yield put(updateProject(action.payload));
    const response = yield call(
      getRoadSiteAPI,
      action.payload.project_site,
      action.payload.road_value
    );
    yield put({
      type: GET_ROAD_SITE_SUCCESS,
      payload: response.data.body,
      meta: response,
    });
  } catch (e: any) {
    if (e.response.status === 440) {
      yield put(sessionExpired());
      return;
    }
    yield put(
      showErrorMessage({
        msg: "주변 도로 생성이 되지 않습니다.",
        errorMessage: e.msg,
        autoClose: 3000,
      })
    );
  }
}

function* runProjectSaga() {
  yield put(createProject());
}

function* generateRoadLineSaga() {
  try {
    yield put(setRoadGeneratorLoading({ loading: true }));
    const projectData = yield select((state: RootState) => state.project.data);
    const response = yield retry(
      3,
      2000,
      generateRoadLineAPI,
      projectData.project_site
    );
    const p2 = response.data.body;
    // const p2 = JSON.parse(r2.Payload as string);
    let boundarySite = [];
    boundarySite.push(p2);
    yield put(
      modifyProject({
        boundary_site: boundarySite,
      })
    );
    yield put({
      type: GENERATE_ROAD_LINE_SUCCESS,
      payload: p2,
      meta: response,
    });
    yield put(setRoadGeneratorLoading({ loading: false }));
    yield put(setRoadGeneratorErrors({}));
  } catch (e: any) {
    yield put(setRoadGeneratorLoading({ loading: false }));
    if (e.response.status === 440) {
      yield put(sessionExpired());
      return;
    }

    yield put(
      showErrorMessage({
        msg: "주변 도로 데이터 수신 실패: 프로젝트를 생성할 수 없습니다.",
        errorMessage: e.msg,
        autoClose: 3000,
      })
    );
    yield put(
      setRoadGeneratorErrors({
        msg: "주변 도로 데이터 생성에 문제가 있습니다.",
      })
    );
    // yield put({
    //   type: CREATE_PROJECT_FAILURE,
    //   payload: e.msg,
    // });
  }
}

function* createProjectSaga(action: any) {
  try {
    const {
      project_site,
      project_site_area,
      project_site_type,
      vacancy_inside,
      project_use_district,
      building_stories_max,
      polygon_calibration,
    }: Project = yield select((state: RootState) => state.project.data);

    const response = yield call(createProjectAPI, {
      project_site,
      project_site_area,
      ...(vacancy_inside !== undefined && vacancy_inside.length > 0
        ? { vacancy_inside }
        : {}),
      ...(project_site_type === "DRAW" ? { draw_mode: true } : {}),
      options: {
        project_use_district,
        ...(building_stories_max !== undefined ? { building_stories_max } : {}),
      },
      polygon_calibration
    });

    yield put({
      type: CREATE_PROJECT_SUCCESS,
      payload: response.data,
      meta: response,
    });
  } catch (e: any) {
    if (e.response.status === 440) {
      yield put(sessionExpired());
      return;
    }
    yield put(
      showErrorMessage({
        msg: "프로젝트 생성 실패",
        errorMessage: e.msg,
        autoClose: 3000,
      })
    );
    yield put({
      type: CREATE_PROJECT_FAILURE,
      payload: e.response.data,
    });
  }
}

function* modifyPnuListSaga(action: any) {
  if (action.payload.length) yield put(getParcelPnu(action.payload));
}

function* updateProjectSaga(action: any) {
  const projectData: ProjectData = yield select((state: RootState) => state.project.data);
  const fieldSelectMode = yield select(
    (state: RootState) => state.project.data.project_site_type
  );
  yield put(modifyPnuList(projectData.field_info_pnu));

  if (projectData.project_site.length) {
    yield put(
      getProjectDefault({
        project_site: projectData.project_site,
        use_district: undefined,
      })
    );
    yield put(getJiguByArea(projectData.origin_project_site));
    if (fieldSelectMode === "SELECT") {
      yield put(generateRoadLine());
    } else {
      yield put(
        modifyProject({
          // DRAW 모드일때, 주변 도로 데이터를 대지 경계선과 같도록.
          boundary_site: projectData.project_site,
        })
      );
    }
  } else {
    yield put(initializeProjectDefault());
    yield put(initializeProject());
    yield put(initializeProjectErrors());
    yield put(initializeJiguByArea());
    yield put(initializeFieldInfo());
    yield put(initializeParcelInfo());
    yield put(initializeProjectDefaultErrors());
  }
}

function* updateProjectUseDistrictSaga(action: any) {
  // 프로젝트 default 정보를 불러와야 하는 경우. project_site가 변경되는 경우,
  const projectData = yield select((state: RootState) => state.project.data);
  if (projectData.project_site.length) {
    yield put(
      getProjectDefault({
        project_site: projectData.project_site,
        use_district: projectData.project_use_district,
      })
    );
  } else {
    yield put(
      showWarningMessage({
        msg: "하나 이상의 필지를 선택하셔야 합니다.",
        autoClose: 3000,
      })
    );
  }
}

function* modifyProjectConfigSaga(action: any) {
  yield put(modifyProject(action.payload));
  const currentProject = yield select((state: RootState) => state.project.data);
  const projectDefaultData = yield select(
    (state: RootState) => state.projectDefault.data
  );
  const defaultErrors = yield select(
    (state: RootState) => state.projectErrors.defaultErrors
  );
  const projectErrors =
    projectDefaultData !== undefined
      ? simInputValidCheck(currentProject)
      : { errs: defaultErrors };

  if (Object.keys(projectErrors.errs).length) {
    yield put(setDefaultErrors(projectErrors.errs)); // projectErros state에 해당 error 정보를 업데이트 해둔다.
  } else {
    yield put(initializeProjectDefaultErrors());
  }
}

function* deletePolygonSaga(action: any) {
  try {
    yield put(initializeConfirm()); // 먼저 생성된 다이얼로그가 먼저 떠 있는 경우, 초기화를 실행하기 위해
    yield put(
      setConfirmType({
        title: "한 개의 영역만 생성 할 수 있습니다",
        children: "정말로 다시 그리시겠습니까?",
      })
    );
    const { yes } = yield race({
      yes: take(OK_CONFIRM),
      no: take(CANCEL_CONFIRM),
    });
    if (yes) {
      const { project_site_type } = yield select(
        (state: RootState) => state.project.data
      );
      yield put(initializeProjectDefault());
      yield put(initializeProject());
      yield put(initializeProjectErrors());
      yield put(initializeJiguByArea());
      yield put(initializeFieldInfo());
      yield put(initializeParcelInfo());
      yield put(initializeProjectDefaultErrors());
      yield put(
        initializeProjectWithMode({
          project_site_type,
        })
      );
      yield put({
        type: SET_DELETE_POLYGON,
        payload: true,
      });
    }
  } catch (e: any) {
    console.log(e.msg);
  }
}

export function* watchProject() {
  yield takeEvery(CREATE_PROJECT, createProjectSaga);
  yield takeEvery(UPDATE_PROJECT, updateProjectSaga);
  yield takeEvery(MODIFY_PNU_LIST, modifyPnuListSaga); // saga effect -> pnu Parcel 정보 불러오기
  yield takeEvery(GENERATE_ROAD_LINE, generateRoadLineSaga);
  yield takeEvery(RUN_PROJECT, runProjectSaga);
  yield takeEvery(UPDATE_USE_DISTRICT, updateProjectUseDistrictSaga);
  yield takeEvery(MODIFY_PROJECT_CONFIG, modifyProjectConfigSaga);
  yield takeEvery(GET_ROAD_SITE, getRoadSiteSaga);
  yield takeEvery(CHANGE_SELECT_MODE, changeSelectModeSaga);
  yield takeLatest(CHANGE_SHAPE_TYPE, changeShapeTypeSaga);
  yield takeEvery(DELETE_POLYGON, deletePolygonSaga);
}

export interface ProjectData extends Project {
  origin_project_site?: Project["project_site"]  
}

const initialState: ProjectState = {
  data: new Project(),
  pnuList: [],
  saveResult: {},
  projectLoading: false,
  genRoad: undefined,
  shapeType: "PROJECT_SITE",
  drawShape: true,
};

const project = handleActions<ProjectState, any>(
  {
    [CREATE_PROJECT]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        ...payload,
      },
      projectLoading: true,
    }),
    [CREATE_PROJECT_SUCCESS]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        ...payload,
      },
      projectLoading: false,
    }),
    [CREATE_PROJECT_FAILURE]: (state, { payload }) => ({
      ...state,
      error: payload.error,
      projectLoading: false,
    }),
    [UPDATE_PROJECT]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        ...payload,
      },
    }),
    [UPDATE_PROJECT_SUCCESS]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        ...payload,
      },
    }),

    [UPDATE_PROJECT_FAILURE]: (state, { payload }) => ({
      ...state,
      error: payload.error,
    }),
    [MODIFY_PROJECT]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        ...payload,
      },
    }),
    // MODIFY_PROJECT_CONFIG
    [UPDATE_USE_DISTRICT]: (state, { payload }) => ({
      // project_use_district가 변경될때, projectDefault 갱신을 위한 Action 분리.
      ...state,
      data: {
        ...state.data,
        ...payload,
      },
    }),

    [MODIFY_PNU_LIST]: (state, { payload }) => ({
      ...state,
      pnuList: payload,
    }),
    [GENERATE_ROAD_LINE_SUCCESS]: (state, { payload }) => ({
      ...state,
      genRoad: {
        ...state.genRoad,
        payload,
      },
    }),
    [GET_ROAD_SITE_SUCCESS]: (state, { payload }) => ({
      // 받아온 데이터  project_site, road_value, road_site 입력
      ...state,
      data: {
        ...state.data,
        ...payload,
      },
    }),
    [GENERATE_ROAD_LINE_FAILURE]: (state, { payload }) => ({
      ...state,
      error: payload.error,
    }),
    [CHANGE_SELECT_MODE]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        project_site_type: payload.mode,
      },
    }),
    [CHANGE_SHAPE_TYPE]: (state, { payload }) => ({
      ...state,
      shapeType: payload.shapeType,
    }),
    [CHANGE_DRAW_SHAPE]: (state, { payload }) => ({
      ...state,
      drawShape: payload.drawShape,
    }),
    [INITIALIZE_PROJECT_WITH_MODE]: (state, { payload }) => ({
      ...initialState,
      data: {
        ...initialState.data,
        project_site_type: payload.project_site_type,
      },
    }),
    [SET_DELETE_POLYGON]: (state, { payload }) => ({
      ...state,
      deletePolygon: payload,
    }),
    // ({
    //   ...state,
    //   error: payload.error,
    // }),
    [INITIALIZE_PROJECT]: () => initialState,
  },
  initialState
);

export default project;
